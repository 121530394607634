import React from 'react'
import mover24_bg from '../../Assets/Home/mover24_bg.png'
import { useNavigate } from 'react-router-dom';

const Ready24Hours = () => {
    const navigate = useNavigate();

    return (
        <div className='bg-black h-auto text-center tracking-wide px-10 py-20'
            style={{ backgroundImage: `url(${mover24_bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}
        >
            <div>
                <p className='text-primaryOrangeHoverColor uppercase font-sans'>About Us</p>
                <h1 className='md:text-3xl text-white text-xl font-medium mt-4' style={{ fontFamily: "Roboto Slab" }}>
                    Movers 24 Hours A Days A Week
                </h1>
                <p className='text-gray-400 mt-4 lg:w-[50%] m-auto font-sans'>
                    We understand that relocation needs can arise at any time. That’s why we offer reliable moving services 24 hours a day, 7 days a week. Whether it’s late night or early morning, our dedicated team is ready to assist you with your move, ensuring a seamless and stress-free experience whenever you need us. Trust us to be there, anytime, anywhere.
                </p>
                <button
                    onClick={() => navigate("/contact")}
                    className="relative inline-flex items-center justify-start px-6 py-2 overflow-hidden font-sans mt-6 cursor-pointer transition-all bg-primaryBlueColor rounded hover:bg-white group">
                    <span className="w-48 h-48 rounded rotate-[-40deg] bg-gradient-to-r from-primaryOrangeColor to-primaryOrangeHoverColor absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                    <span className="relative w-full text-left text-white transition-colors duration-300 ease-in-out group-hover:text-white">
                        Contact Us
                    </span>
                </button>
            </div>
        </div>
    )
}

export default Ready24Hours;