import React, { useState } from 'react'
import van from '../../Assets/Home/van.png'
import { CiDeliveryTruck } from "react-icons/ci";
import { LuPackageOpen } from "react-icons/lu";
import { SiAdguard } from "react-icons/si";
import './TheBestMoving.css';

const cardData = [
    {
        icon: <CiDeliveryTruck size={35} />,
        text: "Free Estimate"
    },
    {
        icon: <LuPackageOpen size={35} />,
        text: "Packaging & Unpacking"
    },
    {
        icon: <SiAdguard size={35} />,
        text: "Moving Protection"
    }
];

const TheBestMoving = () => {
    const [cardHover, setCardHover] = useState(false)
    return (
        <div className='px-10 md:py-20 py-10 font-sans tracking-wide flex xl:flex-row flex-col-reverse xl:gap-20 items-center'>
            <div className='xl:w-[40%] w-full xl:mt-0 mt-10'>
                <img src={van} alt="van-img" className='w-full img-animate' />
            </div>
            <div className='xl:w-[60%]'>
                <div>
                    <p className='text-primaryOrangeHoverColor uppercase'>About Us</p>
                    <h1 className='md:text-4xl text-primaryTextColor text-2xl font-medium mt-4 md:w-[80%]' style={{ fontFamily: "Roboto Slab" }}>
                        Nation’s Top Choice for Stress-Free Relocations
                    </h1>
                    <p className='mt-4 text-secondaryTextColor text-justify'>
                        At AIRGON, we prioritize your peace of mind. Known for our reliability and precision, we tailor every move to meet your specific needs, ensuring a smooth and seamless transition. Trust us to deliver excellence and professionalism, making us the nation’s preferred moving experts.
                    </p>
                    <div className='grid md:grid-cols-3 grid-cols-1 gap-4 mt-4'>
                        {cardData.map((card, index) => (
                            <div
                                key={index}
                                onMouseEnter={() => setCardHover(true)}
                                onMouseLeave={() => setCardHover(false)}
                                className={`py-8 flex flex-col gap-6 justify-center items-center cursor-pointer rounded-md shadow-[0px_10px_1px_rgba(221,_221,_221,_1),_0_10px_20px_rgba(204,_204,_204,_1)] ${cardHover ? 'hover:bg-gradient-to-b from-primaryOrangeColor to-primaryOrangeHoverColor hover:text-white' : 'hover:bg-gradient-to-b from-primaryOrangeColor to-primaryOrangeHoverColor hover:text-white transition duration-300 ease-linear'}`}>
                                {card.icon}
                                <p>{card.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>




        // <div className='px-10 md:py-20 py-10 font-sans tracking-wide flex xl:flex-row flex-col-reverse xl:gap-20  items-center'>
        //     <div className='xl:w-[40%]  w-full xl:mt-0 mt-10'>
        //         <img src={van} alt="van-img" className='w-full' />
        //     </div>
        //     <div className='xl:w-[60%]'>
        //         <div>
        //             <p className='text-primaryOrangeHoverColor uppercase'>About Us</p>
        //             <h1 className='md:text-4xl text-primaryTextColor text-2xl font-medium mt-4 md:w-[80%]' style={{ fontFamily: "Roboto Slab" }}>
        //                 Nation’s Top Choice for Stress-Free Relocations
        //             </h1>
        //             <p className='mt-4 text-secondaryTextColor text-justify'>
        //                 At AIRGON, we prioritize your peace of mind. Known for our reliability and precision, we tailor every move to meet your specific needs, ensuring a smooth and seamless transition. Trust us to deliver excellence and professionalism, making us the nation’s preferred moving experts.
        //             </p>
        //             <div className='grid md:grid-cols-3 grid-cols-1 gap-4 mt-4'>
        //                 {cardData.map((card, index) => (
        //                     <div
        //                         key={index}
        //                         onMouseEnter={() => setCardHover(true)}
        //                         onMouseLeave={() => setCardHover(false)}
        //                         className={`py-8 flex flex-col gap-6 justify-center items-center cursor-pointer rounded-md shadow-[0px_10px_1px_rgba(221,_221,_221,_1),_0_10px_20px_rgba(204,_204,_204,_1)] ${cardHover ? 'hover:bg-gradient-to-b from-primaryOrangeColor to-primaryOrangeHoverColor hover:text-white' : 'hover:bg-gradient-to-b from-primaryOrangeColor to-primaryOrangeHoverColor hover:text-white transition duration-300 ease-linear'}`}>
        //                         {card.icon}
        //                         <p>{card.text}</p>
        //                     </div>
        //                 ))}
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default TheBestMoving;