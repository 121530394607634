import React from 'react';
import MakingYourMoveFast from '../../Components/Home/MakingYourMoveFast';
import TheBestMoving from '../../Components/Home/TheBestMoving';
import ServicesWeProvide from '../../Components/Home/ServicesWeProvide';
import PercentagesValues from '../../Components/Home/PercentagesValues';
import OurPartners from '../../Components/Home/OurPartners';
import Ready24Hours from '../../Components/Home/Ready24Hours';
import WorkProcess from '../../Components/Home/WorkProcess';
// import Vlogs from '../../Components/Home/Vlogs';
import Testimonials from '../../Components/Home/Testimonials';
import TruckImg from '../../Components/Home/TruckImg';

const Home = () => {
  return (
    <React.Fragment>
      <MakingYourMoveFast />
      <TheBestMoving />
      <ServicesWeProvide />
      <PercentagesValues />
      <OurPartners />
      <Ready24Hours />
      <WorkProcess />
      {/* <Vlogs /> */}
      <TruckImg />
      <Testimonials />
    </React.Fragment>
  )
}

export default Home
