import React from 'react';
import img8 from '../../Assets/aeroplane.png';
import img9 from '../../Assets/cycle.png';
import { GiCheckMark } from "react-icons/gi";
import { GiCrossMark } from "react-icons/gi";

const Membership = () => {
    const memberShip = [
        { 'name': 'Basics', 'img': img9, 'price': '3,000', 'colorCode': '#182937' },
        { 'name': 'Express', 'img': img8, 'price': '5,000', 'colorCode': '#E6BC18' },
        { 'name': 'Popular', 'img': img9, 'price': '7,000', 'colorCode': '#182937' }
    ]

    return (
        <React.Fragment>
            <div className='px-10 font-sans text-xl text-center mt-4 text-primaryTextColor'>
                About Us
            </div>

            <h1 className='px-10 text-4xl mt-2 font-bold leading-normal text-center text-primaryTextColor'>
                Our Best Pricing Plans
            </h1>

            <div className='px-10 font-sans text-xl text-center mt-4 text-secondaryTextColor'>
                Explore our range of affordable moving packages: Basic, Standard, Premium, and Custom. Each plan is designed to meet diverse needs and budgets, ensuring a seamless moving experience. Transparent pricing and dedicated support come standard, providing value and peace of mind. Choose the plan that best fits your requirements and let us handle the rest.
            </div>

            <div className='px-10 flex justify-center items-center flex-wrap mt-4'>
                {
                    memberShip?.map((data, i) => {
                        return (
                            <div className="max-w-sm mx-auto mb-4 overflow-hidden bg-white shadow-lg rounded-lg">
                                <div
                                    style={{ backgroundColor: data?.colorCode }}
                                    className='px-5 py-4 text-center'>
                                    <div className='flex justify-center items-center'>
                                        <img
                                            src={data?.img}
                                            alt="Logo"
                                            className="h-16 w-16" />
                                    </div>

                                    <div>
                                        <h2 className="text-2xl font-bold text-white my-4 tracking-wider">
                                            {data?.name}
                                        </h2>

                                        <p className="text-secondaryTextColor font-semibold tracking-wide font-sans">
                                            Package typically refers to the most fundamental level of service or product offering provided by a company
                                        </p>
                                    </div>
                                </div>

                                <div className="p-5 font-sans">
                                    <div className="text-center tracking-wide font-sans space-y-4 text-primaryTextColor font-semibold">
                                        <div className="flex justify-center items-center">
                                            <GiCheckMark size={20} />
                                            <span className='ml-2'>Packaging / Un-packing</span>
                                        </div>

                                        <div className="flex justify-center items-center">
                                            <GiCheckMark size={20} />
                                            <span className='ml-2'>Free use of blankets</span>
                                        </div>

                                        <div className="flex justify-center items-center">
                                            <GiCheckMark size={20} />
                                            <span className='ml-2'>Basic Insurances</span>
                                        </div>

                                        <div className="flex justify-center items-center">
                                            <GiCrossMark size={16} />
                                            <span className='ml-2'>Taxes Included</span>
                                        </div>

                                        <div className="flex justify-center items-center">
                                            <GiCrossMark size={16} />
                                            <span className='ml-2'>Truck & gas fee</span>
                                        </div>
                                    </div>

                                    <div className="text-center mt-4 text-primaryTextColor font-extrabold text-3xl">
                                        {data?.price} /-
                                    </div>

                                    <button
                                        style={{ backgroundColor: data?.colorCode }}
                                        className="mt-8 w-full cursor-pointer tracking-wider text-white font-bold py-2 px-4 rounded">
                                        Inquiry Now
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}

export default Membership
