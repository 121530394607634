import React from "react";
import NotFound from "../Screens/Common/NotFound";
import Home from "../Screens/User/Home";
import About from "../Screens/User/About";
import Contact from "../Screens/User/Contact";
import Services from "../Screens/User/Services";
import Blogs from "../Screens/User/Blogs";
import ServiceDetails from "../Screens/User/ServiceDetails";
import BlogsList from "../Screens/User/BlogsList";

const userRoutes = [
  { path: "/", component: <Home /> },
  { path: "/about", component: <About /> },
  { path: "/contact", component: <Contact /> },
  { path: "/services", component: <Services /> },
  { path: "/blogs", component: <Blogs /> },
  { path: "/service-details/:serviceId", component: <ServiceDetails /> },
  { path: "/blogs-list", component: <BlogsList /> },
  { path: "*", component: <NotFound /> },
];

export { userRoutes };
