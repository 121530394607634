import React from 'react'
import TopNavbar from '../Navbar/TopNavbar'
import BottomNavbar from '../Navbar/BottomNavbar'

const Navbar = () => {
  return (
    <nav className="">
      <TopNavbar />
      <BottomNavbar />
    </nav>
  )
}

export default Navbar
