import React from 'react';
import truck from '../../Assets/pmTruck.png';
import box from '../../Assets/boxes.png';
import road from '../../Assets/roads.png';
import './TruckImg.css';

const TruckImg = () => {
    return (
        <React.Fragment>
            <div className='w-full h-[80vh] flex justify-between items-center relative overflow-hidden'
                style={{
                    backgroundImage: `url(${road})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                <div className='image-container left'>
                    <img src={box} alt="box" />
                </div>
                <div className='image-container right'>
                    <img src={truck} alt="truck" />
                </div>
            </div>
        </React.Fragment>

        // <React.Fragment>
        //     <div className='w-full h-[60vh] flex justify-between items-center relative overflow-hidden'>
        //         <div className='image-container left'>
        //             <img src={box} alt="box" />
        //         </div>
        //         <div className='image-container right'>
        //             <img src={truck} alt="truck" />
        //         </div>
        //     </div>
        // </React.Fragment>
    )
}

export default TruckImg
