import React, { useState, useRef } from "react";
import callus from "../../Assets/Callus.png";
import emailUs from "../../Assets/EmailUs.png";
import Work from "../../Assets/WorkHours.png";
import office from "../../Assets/our Office.png";
import emailjs from "emailjs-com";
import ReadyToGetMoving from "../Home/ReadyToGetMoving";

const ContactUs = () => {
  const initialState = {
    name: "",
    phone: "",
    message: "",
    movingFrom: "",
    movingTo: "",
  };
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [changedMsg, setChangedMsg] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData?.name) {
      isValid = false;
      errors.name = "Name  is required";
    }

    if (!formData?.movingFrom) {
      isValid = false;
      errors.movingFrom = "Starting Location is Required";
    }

    if (!formData?.movingTo) {
      isValid = false;
      errors.movingTo = "Ending Location is Required";
    }

    if (!formData?.phone) {
      isValid = false;
      errors.phoneNo = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData?.phone)) {
      isValid = false;
      errors.phoneNo = "Invalid phone number, must be 10 digits";
    }

    setErrors(errors);
    return isValid;
  };

  const form = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    {
      emailjs
        .sendForm(
          "service_nfyknat",
          "template_bbbs6v8",
          form.current,
          "lUeNyF9ZL2EbTgojp"
        )
        .then(
          (result) => {
            console.log(result.text);
            setFormData(initialState);
            setLoading(false);
            setChangedMsg(
              "Your Details Has Been Received. We Will Contact You Soon!"
            );
          },
          (error) => {
            console.log(error.text);
            setLoading(false);
            setChangedMsg("Something Went Wrong!.Please Try Again");
          }
        );
    }
  };

  return (
    <React.Fragment>
      <div className="px-10 py-5 mb-8">
        <div className="flex flex-col gap-1 items-center justify-center mt-4">
          <div className="text-primaryTextColor text-justify tracking-wider">
            MESSAGE US
          </div>
          <div className="text-primaryTextColor md:text-[40px] text-[20px] font-bold text-justify tracking-wider">
            Request A Quote
          </div>
        </div>

        <div className="flex md:flex-row justify-center gap-10 flex-col mt-4">
          <div className="md:w-[40%] w-full h-[550px]">
            <iframe
              title="Google Map"
              className="h-full w-full"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387221.9985149334!2d-74.25819367574353!3d40.7058251866776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258f6442b692d%3A0xa0b3281fcecc08c!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2suk!4v1641630502934!5m2!1sen!2suk"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="md:w-1/2 w-full  h-[550px]">
            <form ref={form} onSubmit={handleSubmit}>
              <div className="flex flex-col gap-5 mt-4">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData?.name}
                  onChange={handleChange}
                  className="p-4 text-secondaryTextColor placeholder-[#808080] border-[2px]  w-full"
                />
                {errors.name && (
                  <div className="text-red-500 text-sm tracking-wider">
                    {errors.name}
                  </div>
                )}

                <input
                  type="text"
                  name="phone"
                  placeholder="Your Phone No."
                  value={formData?.phone}
                  onChange={handleChange}
                  className="p-4 text-secondaryTextColor placeholder-[#808080] border-[2px]  w-full"
                />
                {errors.phoneNo && (
                  <div className="text-red-500 text-sm tracking-wider">
                    {errors.phoneNo}
                  </div>
                )}

                <input
                  type="text"
                  name="movingFrom"
                  placeholder="Moving From"
                  value={formData?.movingFrom}
                  onChange={handleChange}
                  className="p-4 text-secondaryTextColor placeholder-[#808080] border-[2px]  w-full"
                />
                {errors.phoneNo && (
                  <div className="text-red-500 text-sm tracking-wider">
                    {errors.movingFrom}
                  </div>
                )}

                <input
                  type="text"
                  name="movingTo"
                  placeholder="Moving To"
                  value={formData?.movingTo}
                  onChange={handleChange}
                  className="p-4 text-secondaryTextColor placeholder-[#808080] border-[2px]  w-full"
                />
                {errors.phoneNo && (
                  <div className="text-red-500 text-sm tracking-wider">
                    {errors.movingTo}
                  </div>
                )}

                <textarea
                  name="message"
                  placeholder="message"
                  value={formData?.message}
                  onChange={handleChange}
                  rows="4"
                  className="p-4 text-secondaryTextColor placeholder-[#808080] border-[2px]  w-full"
                />
              </div>

              {changedMsg && (
                <div className="mb-4 text-center text-red-500 mt-5 text-sm tracking-wider">
                  {changedMsg}
                </div>
              )}

              <div className="mt-4 flex justify-center items-center">
                <button
                  disabled={loading}
                  className="bg-primaryBlueColor hover:bg-primaryBlueHoverColor text-white rounded-md p-4 w-full"
                >
                  {loading ? "Please Wait.." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="flex flex-col gap-1 items-center justify-center mt-4">
          <div className="text-primaryTextColor text-justify tracking-wider">
            CONTACT US
          </div>
          <div className="text-primaryTextColor md:text-[40px] text-[20px] font-bold text-justify tracking-wider">
            Get in Touch
          </div>
        </div>

        <div className="flex gap-4 mt-4 md:flex-row justify-between flex-col">
          <div className="flex flex-col gap-2 justify-center items-center">
            <div>
              <img src={office} alt="callus" />
            </div>
            <div>
              <p className="text-primaryBlueHoverColor text-lg tracking-wide font-bold">
                Our Office
              </p>
            </div>
            <div>
              <p className="text-secondaryTextColor tracking-wider text-center">
                Canal Road, Santi Nagar,<br/> Jharpada, Bhubaneswar, Odisha
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-2 justify-center items-center">
            <div>
              <img src={callus} alt="callus" />
            </div>
            <div>
              <p className="text-primaryBlueHoverColor text-lg tracking-wide font-bold">
                Call Us
              </p>
            </div>
            <div>
              <p className="text-secondaryTextColor tracking-wider text-center">
                +91-9090397908
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-2 justify-center items-center">
            <div>
              <img src={emailUs} alt="callus" />
            </div>
            <div>
              <p className="text-primaryBlueHoverColor text-lg tracking-wide font-bold">
                E-Mail
              </p>
            </div>
            <div>
              <p className="text-secondaryTextColor tracking-wider text-center">
                airgonpackers@gmail.com
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-2 justify-center items-center">
            <div>
              <img src={Work} alt="callus" />
            </div>
            <div>
              <p className="text-primaryBlueHoverColor text-lg tracking-wide font-bold">
                Woking Hours
              </p>
            </div>
            <div>
              <p className="text-secondaryTextColor tracking-wider text-center">
                24 x 7 Services
              </p>
            </div>
          </div>
        </div>
      </div>

      <ReadyToGetMoving />
    </React.Fragment>
  );
};

export default ContactUs;
