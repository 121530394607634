import React from 'react'

const Services = () => {
  return (
    <div>
      Services
    </div>
  )
}

export default Services
