import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const Testimonials = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const testimonials = [
        {
            image: 'https://images.pexels.com/photos/5638731/pexels-photo-5638731.jpeg?auto=compress&cs=tinysrgb&w=600',
            author: 'Sidharth Sankar',
            name: 'CEO, Company A',
            content: "I recently used AIRGON for my relocation, and I couldn't be happier with their service. The team was professional, efficient, and took great care of my belongings. They handled everything from packing to unpacking with utmost care, making the entire moving process stress-free for me. Highly recommended!"
        },
        {
            image: 'https://images.pexels.com/photos/2085739/pexels-photo-2085739.jpeg?auto=compress&cs=tinysrgb&w=600',
            author: 'Abhimanyu',
            name: 'CTO, Company B',
            content: "AIRGON  made my move so easy and seamless. Their staff was punctual, friendly, and very organized. They packed my items securely and ensured that nothing was damaged during transit. The entire experience was hassle-free, and I would definitely use their services again."
        },
        {
            image: 'https://images.pexels.com/photos/1042140/pexels-photo-1042140.jpeg?auto=compress&cs=tinysrgb&w=600',
            author: 'Yug Tiwari',
            name: 'COO, Company C',
            content: "We had an excellent experience with AIRGON. The movers were extremely professional, and they handled our items with care. The move was quick and efficient, and all of our belongings arrived intact. I would recommend their services to anyone looking for a trustworthy and efficient moving company."
        },
        {
            image: 'https://images.pexels.com/photos/3696351/pexels-photo-3696351.jpeg?auto=compress&cs=tinysrgb&w=600',
            author: 'Palak Singh',
            name: 'CMO, Company D',
            content: "AIRGON exceeded my expectations in every way. The staff was knowledgeable, careful, and very organized. They handled everything with great efficiency and made sure that my move was stress-free. I was impressed with their professionalism and would not hesitate to use their services again."
        }
    ];

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === testimonials.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? testimonials.length - 1 : prevSlide - 1));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='bg-gradient-to-t from-[#CBD2E5] to-[#FFFFFF] tracking-wide mt-6'>
            <div className='px-10'>
                <p className='text-primaryOrangeHoverColor uppercase'>Reviews</p>
                <h1 className='md:text-3x text-xl font-medium mt-4' style={{ fontFamily: "Roboto Slab" }}>
                    Our Testimonials
                </h1>
            </div>

            <div id="testimonial-carousel" className="relative w-full" data-carousel="slide">
                <div className="relative h-auto overflow-hidden">
                    {testimonials.map((testimonial, index) => (
                        <motion.divdiv
                            key={index}
                            className={`${index === currentSlide ? 'block' : 'hidden'} duration-700 ease-in-out py-6`} data-carousel-item
                            initial={{ opacity: 0 }}
                            animate={{ opacity: currentSlide === index ? 1 : 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div className=" inset-0 flex flex-col justify-center items-center">
                                <p
                                    className="text-normal text-center font-sans w-[80%] text-secondaryTextColor font-medium tracking-wider"
                                    style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                                    {testimonial.content}
                                </p>

                                <img
                                    src={testimonial.image}
                                    className="w-24 h-24 rounded-full mt-2"
                                    alt={`Images of ${testimonial?.name}`}
                                />

                                <h2 className="text-lg font-medium mt-1">{testimonial.author}</h2>
                            </div>
                        </motion.divdiv>
                    ))}
                </div>

                <button onClick={prevSlide} type="button" className="absolute  bg-blue-300 rounded-full top-1/2 transform -translate-y-1/2 left-5 z-30 p-2 bg-opacity-20 hover:bg-opacity-30 focus:outline-none">
                    <svg className="w-6 h-6 text-red" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{ color: 'black' }}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                </button>
                <button onClick={nextSlide} type="button" className="absolute top-1/2 transform -translate-y-1/2 right-5 z-30 p-2 bg-blue-300 bg-opacity-20 rounded-full hover:bg-opacity-30 focus:outline-none">
                    <svg className="w-6 h-6 text-red" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{ color: 'black' }}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                </button>

            </div>
        </div>
    );
};

export default Testimonials;
