import React from 'react';
import logo from '../../Assets/FooterLogo.png';
import { useNavigate } from 'react-router-dom';
import { IoMdMail } from "react-icons/io";
import { IoLocation } from "react-icons/io5";
import { BiSolidPhoneCall } from "react-icons/bi";

import social1 from '../../Assets/footer-fb.png';
import social2 from '../../Assets/footer-insta.png';
import social3 from '../../Assets/footer-twit.png';
import social4 from '../../Assets/footer-yt.png';
import social5 from '../../Assets/footer-li.png';
import servicesData from '../../ourServices.json';

const Footer = () => {
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();

    const handleNavigation = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <React.Fragment>
            <footer className="bg-cover bg-center py-8 bg-[#182937]">
                <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-6 text-white">
                    <div className='md:hidden flex justify-center items-center'>
                        <img
                            src={logo}
                            alt="Logo"
                            className="h-auto w-60" />
                    </div>

                    <div className='space-y-3'>
                        <img
                            src={logo}
                            alt="Logo"
                            className="h-auto w-60 mb-3 hidden md:block" />

                        <p className='hidden md:block font-sans text-sm md:text-base tracking-wider md:text-start text-jusify'>
                            Airgon offers expert relocation services with a focus on safety, efficiency, and customer satisfaction, utilizing advanced technology and eco-friendly practices.
                        </p>

                        <div className='hidden md:flex space-x-2 items-center w-full'>
                            <img
                                src={social1}
                                alt="Logo"
                                className="h-auto w-8 mb-3 cursor-pointer" />

                            <img
                                src={social2}
                                alt="Logo"
                                className="h-auto w-8 mb-3 cursor-pointer" />

                            <img
                                src={social3}
                                alt="Logo"
                                className="h-auto w-8 mb-3 cursor-pointer" />

                            <img
                                src={social4}
                                alt="Logo"
                                className="h-auto w-8 mb-3 cursor-pointer" />

                            <img
                                src={social5}
                                alt="Logo"
                                className="h-auto w-8 mb-3 cursor-pointer" />
                        </div>
                    </div>

                    <div className='hidden md:flex justify-center md:text-start text-center w-full tracking-wider'>
                        <div className='space-y-3'>
                            <h5 className="text-lg font-bold mb-1">
                                Support
                            </h5>

                            <div className='space-y-2 font-sans'>
                                <div
                                    onClick={() => handleNavigation('/')}
                                    className='hover:text-[#FCD200] cursor-pointer'>
                                    Home
                                </div>

                                <div
                                    onClick={() => handleNavigation('/contact')}
                                    className='hover:text-[#FCD200] cursor-pointer'>
                                    Contacts
                                </div>

                                <div
                                    onClick={() => handleNavigation('/about')}
                                    className='hover:text-[#FCD200] cursor-pointer'>
                                    About
                                </div>

                                <div
                                    onClick={() => handleNavigation('/blogs-list')}
                                    className='hover:text-[#FCD200] cursor-pointer'>
                                    Blogs
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='hidden md:flex justify-center md:text-start text-center w-full tracking-wider'>
                        <div className='space-y-3'>
                            <h5 className="text-lg font-bold mb-1">
                                Our Services
                            </h5>
                            <div className='space-y-2 font-sans'>
                                {
                                    servicesData?.services?.map((data, i) => {
                                        return (
                                            <div
                                                onClick={() => navigate(`/service-details/${data.serviceId}`)}
                                                className='hover:text-[#FCD200] cursor-pointer'>
                                                {data.serviceName}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className='space-y-3 tracking-wider md:text-start text-center'>
                        <h5 className="text-lg font-bold mb-1">
                            Contact
                        </h5>

                        <div className='space-y-2 font-sans'>
                            <div className='flex items-center md:justify-start justify-center'>
                                <BiSolidPhoneCall color='#FCD200' size={21} /> <span className='ml-2'>
                                    +91-9090397908
                                </span>
                            </div>

                            <div className='flex items-center md:justify-start justify-center'>
                                <IoMdMail color='#FCD200' size={18} /> <span className='ml-2'>
                                    airgonpackers@gmail.com
                                </span>
                            </div>

                            <div className='flex md:justify-start justify-center'>
                                <IoLocation color='#FCD200' size={34} className='' />
                                <span className='ml-2'>
                                    Canal Road, Santi Nagar, Jharpada, Bhubaneswar, Odisha
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full px-10 mt-6 font-sans tracking-wider text-sm'>
                    <div className="bg-white h-[1px] w-full"></div>

                    <div className='hidden md:flex flex-wrap justify-center items-center mt-6'>
                        <div className='text-white'>
                            <span>© {currentYear}, </span>AIRGON Packers & Movers -&nbsp;
                            <span className='text-white'>
                                All Rights Reserved. |
                            </span>
                        </div>

                        <div className="text-white">
                            Design & Developed by&nbsp;
                            <span
                                className='text-[#fe8740] cursor-pointer'
                                onClick={() => window.open("https://webbocket.com/")}>
                                Web_Bocket Pvt Ltd.</span>
                        </div>
                    </div>

                    <div className='md:hidden block text-center mt-6'>
                        <div className='text-white'>
                            © {currentYear}, AIRGON
                        </div>

                        <div className="text-white my-2">
                            Design & Developed by
                        </div>

                        <div
                            className='text-[#fe8740] cursor-pointer'
                            onClick={() => window.open("https://webbocket.com/")}>
                            Web_Bocket Pvt Ltd.
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment >
    )
}

export default Footer