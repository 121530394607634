import React from "react";
import frame from "../../Assets/pageHeadImg.png";

const PageHeader = ({ pageName }) => {
    return (
        <header
            style={{ backgroundImage: `url(${frame})` }}
            className="bg-cover bg-center flex flex-col md:flex-row items-center w-full h-60 py-4 md:px-10 px-5 md:p-10 "
        >
            <h1 className="border-b-2 border-white pb-2 border-dashed text-white tracking-wider text-3xl md:text-4xl font-bold md:text-start text-center">
                {pageName}
            </h1>
        </header>
    );
};

export default PageHeader;