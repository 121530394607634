import React, { useState, useEffect } from 'react';
import Background_image from '../../Assets/Home/packer_first_img.png';
import Background_image_mobile from '../../Assets/Home/packer_image_mobile.png';
import blank_background from '../../Assets/Home/blank_background.png';
import { useNavigate } from 'react-router-dom';

const MakingYourMoveFast = () => {
    const [ending, setEnding] = useState('');
    const navigate = useNavigate();

    const endings = ['Organized', 'Structured', 'Methodical', 'Orderly', 'Tidy', 'Well-arranged'];

    useEffect(() => {
        const intervalId = setInterval(() => {
            const randomEnding = endings[Math.floor(Math.random() * endings.length)];
            setEnding(randomEnding);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <div>
            {/* tii Large */}
            <div className='lg:flex hidden  h-[700px] px-10  items-center tracking-wider'
                style={{ backgroundImage: `url(${Background_image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}
            >
                <div >
                    <p className='font-sans mb-4 text-xl'>Provide Best Moving Service</p>
                    <h1 className='text-6xl font-bold leading-[80px] bg-gradient-to-br from-[#182937] to-[#EC9230] bg-clip-text text-transparent transition duration-500 ease-linear'>
                        Making Your <br />
                        Move Fast And <br />
                        {ending}
                    </h1>
                    <p className='text-secondaryTextColor font-sans w-[40%] mt-4 mb-4'>
                        Experience effortless moves with our exceptional service. From start to finish, trust us to handle your relocation with care and precision, making your move stress-free and smooth.
                    </p>
                    <button className="relative font-sans inline-flex items-center justify-start py-3 text-sm pl-4 pr-12 overflow-hidden font-medium text-white transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-primaryBlueColor group">
                        <span className="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-primaryOrangeHoverColor group-hover:h-full"></span>
                        <span className="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                            <svg className="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                        </span>
                        <span className="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                            <svg className="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                        </span>
                        <span className="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">View More</span>
                    </button>
                </div>
            </div>

            <div className='lg:hidden contents'>
                <div className='text-center'>
                    <div className='px-10 py-5'
                        style={{ backgroundImage: `url(${blank_background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'middle right' }}
                    >
                        <p className='font-sans mb-4 text-xl'>Provide Best Moving Service</p>
                        <h1 className='md:text-6xl text-4xl  font-bold md:leading-[80px] leading-[50px] bg-gradient-to-br from-[#182937] to-[#EC9230] bg-clip-text text-transparent transition duration-500 ease-linear'>
                            Making Your <br />
                            Move Fast And <br />
                            {ending}
                        </h1>
                        <p className='text-secondaryTextColor font-sans md:w-[90%] mt-4 mb-4 md:text-justify'>
                            Experience effortless moves with our exceptional service. From start to finish, trust us to handle your relocation with care and precision, making your move stress-free and smooth.
                        </p>
                        <button
                            onClick={() => navigate('/about')}
                            className="relative font-sans inline-flex items-center justify-start py-3 text-sm pl-4 pr-12 overflow-hidden font-medium text-white transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-primaryBlueColor group">
                            <span className="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-primaryOrangeHoverColor group-hover:h-full"></span>
                            <span className="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                                <svg className="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                            </span>
                            <span className="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                                <svg className="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                            </span>
                            <span className="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">
                                View More
                            </span>
                        </button>
                    </div>
                    <div className='md:h-[500px] h-[400px]'>
                        <img src={Background_image_mobile} alt="packer_img" className='w-full h-full' />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MakingYourMoveFast;
