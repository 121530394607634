import React from 'react';
import servicesData from '../../ourServices.json';

const BlogsList = () => {
    return (
        <React.Fragment>
            <div className="w-full sm:max-w-5xl sm:mx-auto px-4 my-6 space-y-4">
                {
                    servicesData?.blogs?.map((data, i) => {
                        return (
                            <div>
                                <article className="overflow-hidden rounded shadow ">
                                    <div className="sm:flex sm:flex-wrap">
                                        <div className="sm:w-1/2 h-56 sm:h-auto relative">
                                            <img
                                                className="w-full h-full absolute inset-0 object-cover"
                                                src={data?.image}
                                                alt="blog-img"
                                            />
                                        </div>

                                        <div className="sm:w-1/2 p-4">
                                            <span className="font-sans uppercase tracking-wider inline-block px-2 py-1 rounded-full text-xs bg-primaryOrangeHoverColor font-semibold text-white">
                                                Packers & Movers
                                            </span>

                                            <h4 className="text-lg tracking-wider font-semibold capitalize text-primaryBlueColor mt-2">
                                                {data?.title}
                                            </h4>

                                            <p className="font-sans text-gray-700 mt-2 tracking-wider">
                                                {data?.summary}
                                            </p>

                                            <div className="flex items-center mt-3">
                                                <img
                                                    className="h-10 w-10 object-cover rounded"
                                                    src={data?.author_img}
                                                    alt="authors-img" />

                                                <div className="pl-3 text-sm tracking-wide">
                                                    <p className="truncate text-primaryBlueHoverColor font-semibold">
                                                        {data?.author}
                                                    </p>

                                                    <time className='text-gray-600 font-sans'>
                                                        {data?.date}
                                                    </time>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}

export default BlogsList
