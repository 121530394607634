import React from 'react';
import { useParams } from 'react-router-dom';
import servicesData from '../../ourServices.json';

// Data for the "Local Shifting" service
const serviceDetail = {
    serviceName: "Local Shifting",
    description: "Quick and efficient local relocation services within the same city or area, including all aspects of the move.",
    nav_link: "/",
    pics: [
        "https://example.com/img/local1.jpg",
        "https://example.com/img/local2.jpg"
    ],
    offers: [
        "Free move consultation",
        "20% off for local moves on weekdays"
    ]
};

const ServiceDetails = () => {
    const params = useParams();
    const filteredServices = servicesData.services.filter(s => s.serviceId === params?.serviceId);
    const service = filteredServices[0]

    return (
        <div className="w-full my-6 px-4 sm:px-6 lg:px-10">
            <div className="bg-gradient-to-r from-primaryOrangeColor to-primaryOrangeHoverColor shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-2xl leading-6 text-primaryTextColor tracking-wider font-extrabold">
                        {service.serviceName}
                    </h3>
                </div>

                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="font-sans text-lg font-semibold text-primaryBlueHoverColor">
                                Description
                            </dt>
                            <dd className="font-sans mt-1 text-lg tracking-wide text-primaryTextColor sm:mt-0 sm:col-span-2">
                                {service.description}
                            </dd>
                        </div>

                        <div className="bg-purple-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="font-sans text-lg font-semibold text-primaryBlueHoverColor">
                                Photos
                            </dt>
                            <dd className="mt-1 flex flex-wrap items-center gap-4 sm:col-span-2">
                                {service?.pics?.map((pic, index) => (
                                    <img
                                        key={index}
                                        src={pic}
                                        alt="services-imgs"
                                        className="w-full sm:w-auto sm:h-32 rounded-lg my-2" />
                                ))}
                            </dd>
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="font-sans text-lg font-semibold text-primaryBlueHoverColor">
                            Offers
                            </dt>
                            <dd className="mt-1 text-lg tracking-wide text-primaryTextColor sm:mt-0 sm:col-span-2">
                                <ul className="list-disc pl-5 space-y-1 font-sans">
                                    {service?.offers?.map((offer, index) => (
                                        <li key={index}>
                                            {offer}
                                        </li>
                                    ))}
                                </ul>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default ServiceDetails;
