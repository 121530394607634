import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import emailjs from "emailjs-com";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '500px',
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
};

const BookingModal = ({ open, handleClose }) => {
    const initialState = {
        name: "",
        phone: "",
        message: "",
        movingFrom: "",
        movingTo: "",
    };
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(initialState);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation logic
        if (!formData?.name) {
            toast.error('Please enter a name');
            setLoading(false);
            return;
        }
        if (!formData?.phone || !/^\d{10}$/.test(formData?.phone)) {
            toast.error('Please check the Phone Number & Number should be 10 digits');
            setLoading(false);
            return;
        }
        if (!formData?.movingFrom) {
            toast.error('Please enter Moving From');
            setLoading(false);
            return;
        }
        if (!formData?.movingTo) {
            toast.error('Please enter Moving To');
            setLoading(false);
            return;
        }
        if (!formData?.message) {
            toast.error('Please type a message');
            setLoading(false);
            return;
        }

        // Sending email logic
        emailjs
            .sendForm(
                "service_nfyknat",
                "template_bbbs6v8",
                e.target,
                "lUeNyF9ZL2EbTgojp"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setFormData(initialState);
                    setLoading(false);
                    handleClose();
                    toast.success('Your Details Have Been Received. We Will Contact You Soon!');
                },
                (error) => {
                    console.log(error.text);
                    setLoading(false);
                    toast.error("Something Went Wrong!. Please Try Again");
                    handleClose();
                }
            );
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <h1 className='text-center text-2xl font-sans font-medium mb-8'>Booking Request Form</h1>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-5 mt-4">
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData?.name}
                            onChange={handleChange}
                            className="p-4 text-secondaryTextColor placeholder-[#808080] border-[2px]  w-full"
                        />
                        <input
                            type="text"
                            name="phone"
                            placeholder="Your Phone No."
                            value={formData?.phone}
                            onChange={handleChange}
                            className="p-4 text-secondaryTextColor placeholder-[#808080] border-[2px]  w-full"
                        />
                        <input
                            type="text"
                            name="movingFrom"
                            placeholder="Moving From"
                            value={formData?.movingFrom}
                            onChange={handleChange}
                            className="p-4 text-secondaryTextColor placeholder-[#808080] border-[2px]  w-full"
                        />
                        <input
                            type="text"
                            name="movingTo"
                            placeholder="Moving To"
                            value={formData?.movingTo}
                            onChange={handleChange}
                            className="p-4 text-secondaryTextColor placeholder-[#808080] border-[2px]  w-full"
                        />
                        <textarea
                            name="message"
                            placeholder="Message"
                            value={formData?.message}
                            onChange={handleChange}
                            rows="2"
                            className="p-4 text-secondaryTextColor placeholder-[#808080] border-[2px]  w-full"
                        />
                    </div>

                    <div className="mt-4 flex justify-center items-center">
                        <button
                            disabled={loading}
                            className="bg-primaryBlueColor hover:bg-primaryBlueHoverColor text-white rounded-md p-4 w-full"
                        >
                            {loading ? "Please Wait.." : "Submit"}
                        </button>
                    </div>
                </form>
                <ToastContainer />
            </Box>
        </Modal>
    );
};

export default BookingModal;
