import React, { useEffect, useRef, useState } from 'react';
import whiteBgShape from "../../Assets/whiteShapeBg.png";
import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";

const TeamMembers = () => {

    const allTeamMembers = [
        { "name": "Rajesh Mohanty", "img_url": "https://images.unsplash.com/photo-1607346256330-dee7af15f7c5?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW5kaWFuJTIwbWFsZXxlbnwwfHwwfHx8MA%3D%3D" },
        { "name": "Priyanjan", "img_url": "https://cdn.pixabay.com/photo/2022/03/11/06/14/indian-man-7061278_1280.jpg" },
        { "name": "Sibasis Singh", "img_url": "https://i.pinimg.com/736x/4b/fd/1b/4bfd1b2b8409c76447f0927c472001ea.jpg" },
        { "name": "Amit Kumar", "img_url": "https://img.freepik.com/free-photo/smiley-man-holding-camera-front-view_23-2149915895.jpg" },
        { "name": "Bibek Biswal", "img_url": "https://static.vecteezy.com/system/resources/thumbnails/024/890/252/small/confident-businessman-smiling-outdoors-success-in-corporate-business-expertise-generated-by-ai-free-photo.jpg" },
        { "name": "Trilochan", "img_url": "https://img.freepik.com/free-photo/cheerful-indian-man_1098-18167.jpg" },
        { "name": "Bikash Samal", "img_url": "https://t4.ftcdn.net/jpg/01/56/19/15/360_F_156191504_F8KusEJnAdRbyztflKKtQnnU43GIyWv4.jpg" },
        { "name": "Jyoti Prakash", "img_url": "https://cdn.pixabay.com/photo/2020/03/09/21/53/indian-4917258_1280.jpg" },
    ]

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                setScrollPosition(containerRef.current.scrollLeft);
            }
        };

        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const containerRef = useRef(null);

    const scrollLeft = () => {
        if (containerRef.current) {
            const cardWidth = containerRef.current.children[0]?.offsetWidth || 0;
            containerRef.current.scrollBy({
                left: -cardWidth,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            const cardWidth = containerRef.current.children[0]?.offsetWidth || 0;
            containerRef.current.scrollBy({
                left: cardWidth,
                behavior: 'smooth',
            });
        }
    };

    const isPrevDisabled = scrollPosition <= 0;
    const isNextDisabled =
        containerRef.current &&
        scrollPosition >= containerRef.current.scrollWidth - containerRef.current.offsetWidth;


    return (
        <React.Fragment>
            <div className='px-10 font-sans text-xl text-center mt-4 text-primaryTextColor'>
                Peoples
            </div>

            <h1 className='px-10 text-4xl mt-2 font-bold leading-normal text-center text-primaryTextColor'>
                Our Team Members
            </h1>

            <div className='px-10 font-sans text-xl text-center mt-4 text-secondaryTextColor'>
            Meet the dedicated professionals behind AIRGON. Our team comprises industry experts who bring years of experience and a passion for service to every move. From meticulous planners and skilled packers to expert drivers and responsive customer support, each member is committed to ensuring a smooth and stress-free relocation for every client. Get to know the faces that will guide you home.
            </div>

            <div className='h-[auto] px-10 my-6'>
                <div
                    ref={containerRef}
                    className='flex gap-[10px] overflow-scroll pb-10 pl-0'
                    style={{ scrollbarWidth: 'none', '-ms-overflow-style': 'none', 'scrollbar-color': 'transparent' }}
                >
                    {
                        allTeamMembers.map((data, index) => (
                            <div key={index} className='flex-none w-[360px]'>
                                <div className='h-[400px] rounded-lg overflow-hidden border-8 border-transparent'>
                                    <img className='w-[100%] h-[100%] rounded-xl' src={data.img_url} alt="" />
                                </div>

                                <div className=''>
                                    <img className='lg:w-[75%] md:w-[90%] w-[75%] m-auto relative xl:mt-[-80px] md:mt-[-60px] mt-[-80px]' src={whiteBgShape} alt="" />
                                    <div className=' pl-[20%] relative xl:mt-[-85px] lg:mt-[-80px] md:mt-[-90px] mt-[-80px]' >
                                        <h1 className='font-bold tracking-wider'>{data.name}</h1>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className='flex items-center justify-between gap-2'>
                    <button
                        onClick={scrollLeft}
                        disabled={isPrevDisabled}
                        type='button'
                        className={`${isPrevDisabled
                            ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            : 'text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-2 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800'
                            } font-medium rounded-full text-sm p-2 pl-1.5 text-center absolute mt-[-500px] md:ml-[-16px] ml-[-35px]`}
                    >
                        <GrCaretPrevious />
                    </button>
                    <button
                        onClick={scrollRight}
                        disabled={isNextDisabled}
                        type='button'
                        className={`${isNextDisabled
                            ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            : 'text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-2 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800'
                            } font-medium rounded-full text-sm p-2 pr-1.5 text-center absolute mt-[-500px] xl:ml-[93%] lg:ml-[91%] md:ml-[88%] ml-[83%]`}
                    >
                        <GrCaretNext />
                    </button>
                </div>

            </div>
        </React.Fragment>
    )
}

export default TeamMembers