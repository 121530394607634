import React from 'react'
import last_bg from '../../Assets/Home/last_bg.png'
import packer_boy_image from '../../Assets/Home/packer_boy_image.png'
import packing_img from '../../Assets/Home/packing_img.png'

const ReadyToGetMoving = () => {
    return (
        <div
            className='h-auto  px-10 flex lg:flex-row flex-col'
            style={{ backgroundImage: `url(${last_bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom' }}
        >
            <div className='lg:w-[60%] md:grid lg:grid-cols-1 md:grid-cols-2 flex flex-col-reverse  gap-8 py-5 '>
                <div className=''>
                    <img src={packing_img} alt="" className='xl:w-[200px] lg:w-[150px]' />
                </div>
                <div className='md:text-white tracking-wide font-sans   flex items-center'>
                    <div>
                        <h1 className='text-2xl font-medium'>Ready To Get Moving? Get Your Free Quote</h1>
                        <p className='text-sm lg:w-[70%] mt-2'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                            ullamcorper mattis, pulvinar dapibus leo consectetur adipiscing.
                        </p>
                        <div className='flex mt-2 font-medium lg:text-2xl'>
                            <h1>Call Us : </h1>
                            <h1 className='text-primaryOrangeColor'>+(62) 800-567-8990</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lg:w-[40%]  flex items-end'>
                <img src={packer_boy_image} alt="" className='w-[100%]' />
            </div>
        </div>
    )
}

export default ReadyToGetMoving;