import React from 'react';
import { GiNotebook } from "react-icons/gi";
import { LuPackageCheck } from "react-icons/lu";
import { LuTruck } from "react-icons/lu";
import { GoGift } from "react-icons/go";


const WorkProcess = () => {
    // Define an array of objects representing each step
    const steps = [
        {
            icon: <GiNotebook size={40} />,
            title: 'Book Your Service',
            description: 'Schedule a convenient pickup date and time with our easy-to-use online booking system. Our team is ready to assist you with a seamless start to your service experience.'
        },

        {
            icon: <LuPackageCheck size={40} />,
            title: 'Pack Your Good',
            description: 'Ensure your items are secure and ready for transit with our expert packing services. We provide high-quality materials and techniques tailored to protect your belongings.'
        },

        {
            icon: <LuTruck size={40} />,
            title: 'Safe Loading',
            description: 'Our professional crew handles every item with care. We employ systematic loading procedures to maximize safety and space, ensuring a secure journey for your goods.'
        },

        {
            icon: <GoGift size={40} />,
            title: 'Safe Delivery',
            description: 'Receive your items intact at your destination. Our meticulous planning and tracking systems guarantee your belongings arrive on time and in perfect condition.'
        },
    ];

    return (
        <div className='px-10 py-10 bg-gradient-to-b from-[#98A7CC] to-[#FFFFFF]'>
            <p className='text-primaryOrangeHoverColor uppercase font-sans'>Work Process</p>
            <h1 className='md:text-3xl text-xl font-medium mt-4' style={{ fontFamily: "Roboto Slab" }}>
                Make It Happens In 4 Steps
            </h1>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-4'>
                {
                    steps.map((step, index) => (
                        <div key={index} className='w-full text-center px-5 py-5'>
                            <div>
                                <div className='border-2 border-black p-4 rounded-full bg-white w-[120px] h-[120px] flex items-center justify-center m-auto'>
                                    {step.icon}
                                </div>
                                <h1 className='text-lg font-sans font-medium mt-2'>{step.title}</h1>
                                <p className='font-sans text-sm mt-2 text-secondaryTextColor'>
                                    {step.description}
                                </p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default WorkProcess;
