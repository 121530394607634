import React, { useState } from 'react'
import white_bg_mage from '../../Assets/Home/black&white_bg_mage.png';
import percentage1 from '../../Assets/Home/percentage1.png';
import percentage2 from '../../Assets/Home/percentage2.png';


const PercentagesValues = () => {
    const skillsData = [
        { title: 'Project Completed', percent: 95, image: percentage1 },
        { title: 'Packages Delivered', percent: 70, image: percentage2 },
        { title: 'Commercial Goods', percent: 90, image: percentage1 },
        { title: 'Delivered On Time', percent: 50, image: percentage2 },
    ];

    return (
        <div
            className='h-auto px-10 py-10'
            style={{ backgroundImage: `url(${white_bg_mage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'top' }}
        >
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4'>
                {
                    skillsData?.map((ele) =>
                        <div className="flex flex-col items-center justify-center gap-4 bg-white py-6 px-4 rounded shadow-[10px_10px_1px_rgba(221,_221,_221,_1),_0_10px_20px_rgba(204,_204,_204,_1)]">
                            <div>
                                <PercentageCircle percent={ele?.percent} />
                            </div>
                            <h1 className='font-sans font-bold'>{ele?.title}</h1>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default PercentagesValues



const PercentageCircle = ({ percent }) => {
    const dashArray = 100;
    const dashOffset = dashArray - (dashArray * percent) / 100;

    return (
        <div className="relative size-40">
            <svg
                className="size-full"
                width="90"
                height="90"
                viewBox="0 0 36 36"
                xmlns="http://www.w3.org/2000/svg"
            >
                {/* Background Circle */}
                <circle
                    cx="18"
                    cy="18"
                    r="16"
                    fill="none"
                    className="stroke-current text-gray-200 dark:text-neutral-700"
                    strokeWidth="2"
                ></circle>
                {/* Progress Circle inside a group with rotation */}
                <g className="origin-center -rotate-90 transform">
                    <circle
                        cx="18"
                        cy="18"
                        r="16"
                        fill="none"
                        className="stroke-current text-primaryOrangeHoverColor"
                        strokeWidth="2"
                        strokeDasharray={dashArray}
                        strokeDashoffset={dashOffset}
                    ></circle>
                </g>
            </svg>
            {/* Percentage Text */}
            <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <span className="text-center text-2xl font-bold text-gray-800 dark:text-white">
                    {percent}%
                </span>
            </div>
        </div>
    );
};
