import React, { useState } from 'react';
import PageHeader from '../../Components/Common/PageHeader';
import img1 from '../../Assets/Layer_1.png';
import img2 from '../../Assets/workers.png';
import img3 from '../../Assets/FrameBg.png';

import img4 from '../../Assets/stars.png';
import img5 from '../../Assets/cart.png';
import img6 from '../../Assets/truck.png';
import img7 from '../../Assets/group.png';
import Membership from '../../Components/About/Membership';
import TeamMembers from '../../Components/About/TeamMembers';
import BookingModal from '../../Components/Common/BookingModal';

const About = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <PageHeader
        pageName="About Us"
      />
      <BookingModal open={open} handleClose={handleClose} />
      <div className=''>
        <div className='px-10 font-sans text-xl text-center mt-4 text-primaryTextColor'>
          Provide Best Moving Service
        </div>

        <h1 className='px-10 text-4xl mt-2 font-bold leading-normal text-center text-primaryTextColor'>
          We give you Complete Control<br /> of your Shipment.
        </h1>

        <div className='px-10 font-sans text-xl text-center mt-4 text-secondaryTextColor'>
          At AIRGON, we understand that moving is more than just transporting items from one place to another—it's about ensuring a seamless and stress-free transition to your new beginning. That's why we offer an innovative approach that puts you in complete control of your shipment.
        </div>

        <div className='px-10 w-full my-4'>
          <img
            src={img1}
            alt="Logo"
            className="h-auto w-full mb-3 cursor-pointer" />
        </div>

        <div className="px-10 mx-auto">
          <div className="flex flex-col md:flex-row items-center">
            <div className="flex-1">
              <img src={img2}
                alt="workers"
                className="max-w-full h-auto rounded-lg shadow-md"
              />
            </div>

            <div className="flex-1 mt-4 md:mt-0 md:ml-20">
              <div className='font-sans text-xl mt-4 text-primaryTextColor'>
                Why Choose Us ..?
              </div>

              <h1 className="text-3xl mt-4 font-bold text-gray-800 tracking-wider">
                We Professional Moving Company
              </h1>

              <p className="mt-4 font-sans text-xl tracking-wide text-secondaryTextColor text-justify">
                <b>Expert Handling:</b> Our experienced team ensures your belongings are packed, moved, and delivered with utmost care.
                <br /><br />
                <b>On-Time Delivery:</b> We respect your schedule and guarantee timely delivery, so you can settle into your new home without delay.
                <br /><br />
                <b>Transparent Pricing:</b> Enjoy clear, upfront pricing without any hidden fees, making your move predictable and stress-free.
                <br /><br />
                <b>Personalized Service:</b> Every move is tailored to meet your specific needs, providing a flexible and responsive moving experience.
                <br /><br />
                <b>Full Coverage:</b> With comprehensive insurance and meticulous handling, we prioritize the security and safety of your belongings.
                <br /><br />
                <b>Dedicated Support:</b> Our customer service team is always ready to assist, ensuring a smooth and satisfactory moving process.
              </p>

              <div className='w-full p-4 my-4 rounded-md border border-primaryBlueHoverColor border-dashed text-center'>
                <div className='font-sans font-extrabold text-2xl text-[#FCD200]'>
                  Call Us, To Ask Any Question.
                </div>

                <div className='font-sans font-bold mt-4 text-xl text-primaryTextColorkb'>
                  7008195567, 9978298767
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="relative mt-4 p-10 tracking-wider"
          style={{ backgroundImage: `url(${img3})` }}
        >
          <div className="container mx-auto p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              <div className="flex flex-col items-center justify-center">
                <img src={img4}
                  alt="Name1"
                  className="w-24 h-24" />

                <p className="text-3xl mt-2 font-extrabold text-white">
                  2000 +
                </p>

                <p className="mt-2 text-white font-sans text-xl">
                  Client Trust Us
                </p>
              </div>

              <div className="flex flex-col items-center justify-center">
                <img src={img5}
                  alt="Name2"
                  className="w-24 h-24" />

                <p className="text-3xl mt-2 font-extrabold text-white">
                  20000 +
                </p>

                <p className="mt-2 text-white font-sans text-xl">
                  Tons of Goods
                </p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <img src={img6}
                  alt="Name3"
                  className="w-24 h-24" />

                <p className="text-3xl mt-2 font-extrabold text-white">
                  10000 +
                </p>

                <p className="mt-2 text-white font-sans text-xl">
                  Km for Year
                </p>
              </div>

              <div className="flex flex-col items-center justify-center">
                <img src={img7}
                  alt="Name4"
                  className="w-24 h-24" />

                <p className="text-3xl mt-2 font-extrabold text-white">
                  200 +
                </p>

                <p className="mt-2 text-white font-sans text-xl">
                  Team Members
                </p>
              </div>

            </div>
          </div>
        </div>

        <Membership />

        <TeamMembers />

        <div className="rounded-bl-[3rem] rounded-tr-[3rem] mx-10 mb-4 bg-gradient-to-r from-[#0B1F77] to-[#17398B] flex items-center justify-center">
          <div className="max-w-4xl w-full mx-auto py-10 flex items-center justify-between space-x-4">
            <div className="flex-1">
              <div className='font-sans text-xl mt-4 text-white'>
                Need more consultation
              </div>

              <h1 className='text-4xl mt-2 font-bold leading-normal text-white tracking-wider'>
                Welcome to Our Service
              </h1>
            </div>

            <div>
              <button onClick={handleOpen} className="relative tracking-wider inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium transition-all bg-[#8089B0] rounded hover:bg-white group">
                <span className="w-48 h-48 rounded rotate-[-40deg] bg-gradient-to-r from-primaryOrangeColor to-primaryOrangeHoverColor absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                <span className="relative w-full text-left text-white transition-colors duration-300 ease-in-out group-hover:text-white">
                  Get a Booking !
                </span>
              </button>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment >
  )
}

export default About