import React from 'react'
import map from '../../Assets/Home/map.png'
import image1 from '../../Assets/Home/courier.png'
import image2 from '../../Assets/Home/fast_courior.png'
import image3 from '../../Assets/Home/fuzzo.png'

const OurPartners = () => {
    return (
        <div className='grid lg:grid-cols-2 grid-cols-1 px-10 py-10 lg:gap-4 gap-10'>
            <div className='flex items-center'>
                <div>
                    <p className='text-primaryOrangeHoverColor uppercase'>Our Partners</p>
                    <h1 className='md:text-4xl text-primaryTextColor text-2xl font-medium mt-4 xl:w-[60%]' style={{ fontFamily: "Roboto Slab" }}>
                        Over 50+ Partners Locations In The World
                    </h1>
                    <p className='mt-4 font-sans text-secondaryTextColor'>
                        At AIRGON, our vast network spans over 50 partner locations worldwide, enabling us to provide local expertise on a global scale. Whether you're relocating, shipping, or in need of local services, trust us to connect you seamlessly across continents. Our partnerships enhance our capabilities and ensure high-quality service, wherever you are.
                    </p>
                    <div className='grid md:grid-cols-3 grid-cols-2 items-center justify-between gap-8 lg:mt-4 mt-8'>
                        <img src={image1} alt="" className='w-full' />
                        <img src={image2} alt="" className='w-full' />
                        <img src={image3} alt="" className='w-full' />
                    </div>
                </div>
            </div>
            <div>
                <img src={map} alt="map" />
            </div>
        </div>
    )
}

export default OurPartners