import React from 'react';
import ContactUs from '../../Components/Contact/ContactUs'
import PageHeader from '../../Components/Common/PageHeader'

const Contact = () => {
  return (
    <React.Fragment>
       <PageHeader
        pageName="Contact Us" />
      <ContactUs/>
    </React.Fragment>
  )
}

export default Contact
