import React, { useState } from 'react';
import bg from '../../Assets/Home/ServiceProvide_Bg_image.png';
import { FaGlobeAsia, FaGift } from "react-icons/fa";
import { GrStorage } from "react-icons/gr";
import { MdOutlineAddShoppingCart, MdOutlineSdStorage } from "react-icons/md";
import { MdMoveDown } from "react-icons/md";
import { FaTruckLoading } from "react-icons/fa";

const ServicesWeProvide = () => {
    const [hoverCard, setHoverCard] = useState(null);

    const cardData = [
        {
            title: "Global Relocation Experts",
            description: "Expertly handling international moves, we ensure a seamless transition worldwide with tailored logistics and dedicated support. Trust World Moving for your journey across borders.",
            icons: <FaGlobeAsia size={30} />
        },
        {
            title: "Secure Storage Solutions",
            description: "Discover reliable and accessible storage with AIRGON. Our secure facilities are equipped with advanced security systems and offer a variety of unit sizes to accommodate all your storage needs. Trust us to protect your valuables with care and professionalism.",
            icons: <MdOutlineSdStorage size={30} />
        },
        {
            title: "Complete Moving Kits for Every Need",
            description: "Equip yourself with Airgon's moving kits, designed for efficiency and ease. Each kit includes essential, high-quality packing supplies, ensuring a smooth and organized move. Perfect for any relocation size, our kits help streamline your packing process.",
            icons: <MdOutlineAddShoppingCart size={30} />
        },
        {
            title: "Expert Long-Distance Moving Services",
            description: "At Airgon, we specialize in seamless long-distance moves. Our expert team manages every aspect from careful packing to secure transportation, ensuring your move is effortless and stress-free. Trust us for meticulous service that spans every mile.",
            icons: <MdMoveDown size={30} />
        },
        {
            title: "Fast & Reliable Box Delivery",
            description: "We offers speedy and dependable box delivery services to meet all your packing needs. Whether you’re moving, storing, or shipping items, our variety of high-quality boxes ensures your contents stay secure. Choose us for convenient delivery right to your doorstep, tailored to your schedule and requirements.",
            icons: <FaGift size={30} />
        },
        {
            title: "Efficient Loading & Unloading Services",
            description: "We provide professional loading and unloading services designed to make your move hassle-free. Our trained team handles your belongings with care and precision, ensuring everything is loaded and unloaded safely. Whether you're moving across town or the country, trust us to manage the heavy lifting and streamline your relocation process.",
            icons: <FaTruckLoading size={30} />
        }
    ];

    return (
        <div className='h-auto p-10 tracking-wide'
            style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}
        >
            <div className='text-center font-sans'>
                <p className='text-primaryOrangeHoverColor uppercase'>About Us</p>
                <h1 className='md:text-3xl text-white text-xl font-medium mt-4' style={{ fontFamily: "Roboto Slab" }}>
                    Services We Provide
                </h1>
                <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-y-4 text-white mt-6'>
                    {cardData.map((card, index) => (
                        <div
                            key={index}
                            onMouseEnter={() => setHoverCard(index)}
                            onMouseLeave={() => setHoverCard(null)}
                            className={`py-5 hover:bg-gradient-to-br from-primaryOrangeColor to-primaryOrangeHoverColor rounded ${hoverCard === index ? 'text-white' : ''}`}>
                            {/* <FaGlobeAsia size={30} className={` ${hoverCard === index ? 'text-white' : 'text-primaryOrangeHoverColor'} m-auto`} /> */}
                            <div className={` ${hoverCard === index ? 'text-white' : 'text-primaryOrangeHoverColor'} flex items-center justify-center`}>
                                {card?.icons}
                            </div>
                            <h1 className='font-bold text-xl mt-4 mb-4'>{card.title}</h1>
                            <p className='m-auto lg:w-[70%] md:w-[90%] w-[100%] '>{card.description}</p>
                            <button className={`mt-4 uppercase text-primaryOrangeHoverColor cursor-pointer ${hoverCard === index ? 'text-white' : ''}`}>Read More</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ServicesWeProvide;
