import React from 'react'
import bg from '../../Assets/hamburger_bg.svg';
import { FaFacebookF, FaPhoneAlt, FaYoutube } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

const TopNavbar = () => {
    return (
        <div className='lg:contents hidden'>
            <div className='tracking-wider px-10 py-3 font-sans flex items-center justify-between overflow-hidden' style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}>
                <div className='text-white flex gap-8 text-sm font-medium'>
                    <div className='flex items-center gap-2 '>
                        <FaPhoneAlt />
                        <p>+91-9090397908</p>
                    </div>
                    <div className='flex items-center gap-2'>
                        <IoMdMail />
                        <p>airgonpackers@gmail.com</p>
                    </div>
                </div>
                <div className='flex items-center gap-4'>
                    <FaFacebookF className='text-lg cursor-pointer' />
                    <FaYoutube className='text-lg cursor-pointer' />
                    <RiInstagramFill className='text-lg cursor-pointer' />
                    <FaXTwitter className='text-lg cursor-pointer' />
                </div>
            </div>
        </div>
    )
}

export default TopNavbar